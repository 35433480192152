const BASE = '/';
const LOGIN = '/login';

const REPORTS = '/reports';
const REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES = '/reports/documents-for-parties';
const REPORT_PROJECT_FOLDERS_WO_PROJECTS = '/reports/project-folders-missing-project';

const PARTIES = '/parties-list';
const CLIENT_MANAGEMENT = PARTIES;

const NEW_PARTY = '/new-party';
const NEW_CLIENT = NEW_PARTY;

const CLIENT_PAGES = '/party/:id/:tab';
const CLIENT_DETAILS = '/party/:id/details';
const CLIENT_EVENTS = '/party/:id/events-log';

const DOCUMENTS = '/dashboard';

const DASHBOARD = '/dashboard';

const DOCUSIGN_COMPLETE = '/docusign-complete';

const ARCHIVE_FILES = '/pre-lms';
const ARCHIVE_FILE_PAGES = '/pre-lms-file/:archiveFileId/:tab';
const ARCHIVE_FILE_DETAILS = '/pre-lms-file/:archiveFileId/details';
const ARCHIVE_FILE_EVENTS = '/pre-lms-file/:archiveFileId/events-log';

const clientPathConst = {
  BASE,
  LOGIN,
  DOCUSIGN_COMPLETE,

  CLIENT_MANAGEMENT,
  PARTIES,
  DOCUMENTS,
  NEW_PARTY,
  NEW_CLIENT,
  CLIENT_PAGES,
  CLIENT_DETAILS,
  CLIENT_EVENTS,
  DASHBOARD,

  REPORTS,
  REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES,
  REPORT_PROJECT_FOLDERS_WO_PROJECTS,

  ARCHIVE_FILES,
  ARCHIVE_FILE_PAGES,
  ARCHIVE_FILE_DETAILS,
  ARCHIVE_FILE_EVENTS,
};

export default clientPathConst;

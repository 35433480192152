import paths from './paths';

import { Documents as DocumentsComponent, Login, Parties, TreeTableRoute } from '../pages';

import { NewPartie as NewPartyComponent } from 'pages/NewPartie/NewPartie';

import { TreeTableDocument as TreeTableDocumentComponent } from 'pages/TreeTableRoute/components/TreeTableDocument/TreeTableDocument';
import { TreeTableDocumentFileManager as TreeTableDocumentFileManagerComponent } from 'pages/TreeTableRoute/components/TreeTableDocumentFileManager/TreeTableDocumentFileManager';
import { TreeTableHome as TreeTableHomeComponent } from 'pages/TreeTableRoute/components/TreeTableHome/TreeTableHome';
import { TreeTableLegalFolder as TreeTableLegalFolderComponent } from 'pages/TreeTableRoute/components/TreeTableLegalFolder/TreeTableLegalFolder';
import { TreeTableLegalFolderProjectFolders as TreeTableLegalFolderProjectFoldersComponent } from 'pages/TreeTableRoute/components/TreeTableLegalFolderProjectFolders/TreeTableLegalFolderProjectFolders';
import { TreeTableNewDocument as TreeTableNewDocumentComponent } from 'pages/TreeTableRoute/components/TreeTableNewDocument/TreeTableNewDocument';
import { TreeTableNewLegalFolder as TreeTableNewLegalFolderComponent } from 'pages/TreeTableRoute/components/TreeTableNewLegalFolder/TreeTableNewLegalFolder';
import { TreeTableNewProjectFolder as TreeTableNewProjectFolderComponent } from 'pages/TreeTableRoute/components/TreeTableNewProjectFolder/TreeTableNewProjectFolder';
import { TreeTableProjectFolder as TreeTableProjectFolderComponent } from 'pages/TreeTableRoute/components/TreeTableProjectFolder/TreeTableProjectFolder';

import { ClientPage as ClientPageComponent } from 'pages/Client/Client';
import { ClientDetails as ClientDetailsComponent } from 'pages/Client/components/ClientDetails/ClientDetails';
import { ClientEvents as ClientEventsComponent } from 'pages/Client/components/ClientEvents/ClientEvents';

import { ArchiveFileRoutePage } from 'pages/ArchiveFile/ArchiveFileRoute';
import { ArchiveFileDetails } from 'pages/ArchiveFile/components/ArchiveFileDetails/ArchiveFileDetails';
import { ArchiveFileEvents } from 'pages/ArchiveFile/components/ArchiveFileEvents/ArchiveFileEvents';
import { ArchiveFilesPage } from 'pages/ArchiveFiles/ArchiveFiles';
import { DocusignCompletePage } from 'pages/DocusignComplete/DocusignComplete';
import { DocumentsNotSignedByAllParties as ReportDocumentsNotSignedByAllPartiesComponent } from 'pages/Reports/DocumentsNotSignedByAllParties/DocumentsNotSignedByAllParties';
import { ProjectFoldersWoProject as ReportProjectFoldersWoProjectComponent } from 'pages/Reports/ProjectFoldersWoProject/ProjectFoldersWoProject';
import { ReportsPage as ReportPageComponent } from 'pages/Reports/Reports';

const routes = [
  {
    path: paths.client.DOCUSIGN_COMPLETE,
    exact: true,
    component: DocusignCompletePage,
    private: true,
    guest: false,
  },

  {
    path: paths.client.PARTIES,
    exact: true,
    component: Parties,
    private: true,
  },
  {
    path: paths.client.DOCUMENTS,
    exact: true,
    component: DocumentsComponent,
    private: true,
  },
  {
    path: paths.client.NEW_PARTY,
    exact: true,
    component: NewPartyComponent,
    private: true,
  },
  {
    path: paths.client.CLIENT_PAGES,
    exact: false,
    component: ClientPageComponent,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.client.CLIENT_DETAILS,
        exact: true,
        component: ClientDetailsComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.CLIENT_EVENTS,
        exact: true,
        component: ClientEventsComponent,
        private: true,
        guest: false,
      },
    ],
  },
  {
    path: paths.client.LOGIN,
    exact: true,
    component: Login,
    guest: true,
  },

  {
    path: paths.treeViewClient.TREEVIEW_HOME,
    exact: false,
    component: TreeTableRoute,
    private: true,
    guest: false,
    routes: [
      {
        path: paths.treeViewClient.TREEVIEW_HOME,
        exact: true,
        component: TreeTableHomeComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_NEW,
        exact: true,
        component: TreeTableNewLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDER_NEW,
        exact: true,
        component: TreeTableNewProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_ARCHIVE,
        exact: true,
        component: TreeTableLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_TAB,
        exact: true,
        component: TreeTableLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER_PROJECT_FOLDERS,
        exact: true,
        component: TreeTableLegalFolderProjectFoldersComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_LEGAL_FOLDER,
        exact: true,
        component: TreeTableLegalFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_DOCUMENT_NEW,
        exact: true,
        component: TreeTableNewDocumentComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER_TAB,
        exact: true,
        component: TreeTableProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_PROJECT_FOLDER,
        exact: true,
        component: TreeTableProjectFolderComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_DOCUMENT,
        exact: true,
        component: TreeTableDocumentComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.treeViewClient.TREEVIEW_DOCUMENT_FM,
        exact: true,
        component: TreeTableDocumentFileManagerComponent,
        private: true,
        guest: false,
      },
      {
        path: paths.client.REPORTS,
        exact: false,
        component: ReportPageComponent,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.REPORT_DOCUMENTS_NOT_SIGNED_BY_ALL_PARTIES,
            exact: true,
            component: ReportDocumentsNotSignedByAllPartiesComponent,
            private: true,
            guest: false,
          },
          {
            path: paths.client.REPORT_PROJECT_FOLDERS_WO_PROJECTS,
            exact: true,
            component: ReportProjectFoldersWoProjectComponent,
            private: true,
            guest: false,
          },
        ],
      },
      {
        path: paths.client.ARCHIVE_FILES,
        exact: true,
        component: ArchiveFilesPage,
        private: true,
        guest: false,
      },
      {
        path: paths.client.ARCHIVE_FILE_PAGES,
        exact: false,
        component: ArchiveFileRoutePage,
        private: true,
        guest: false,
        routes: [
          {
            path: paths.client.ARCHIVE_FILE_DETAILS,
            exact: true,
            component: ArchiveFileDetails,
            private: true,
            guest: false,
          },
          {
            path: paths.client.ARCHIVE_FILE_EVENTS,
            exact: true,
            component: ArchiveFileEvents,
            private: true,
            guest: false,
          },
        ],
      },
    ],
  },
];

export default routes;
